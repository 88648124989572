.react-pdf__Page {
  display: flex;
  justify-content: center;
}

.react-pdf__Page__canvas, .react-pdf__Page__annotations, .react-pdf__Page__textContent {
  margin: 0 auto !important;
  height: 80% !important;
  width: 70% !important;
}

.react-pdf__Page__canvas {
  border: 1px solid gray;
  /* max-height: 50vh; */
}