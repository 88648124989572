@import url('/node_modules/flatpickr/dist/themes/light.css'); 
@tailwind base;
@tailwind components;
@tailwind utilities;

::-ms-reveal {
  display: none;
}

@font-face {
  font-family: "cardif-icons";
  src: url("../public/fonts/cardif-icons.ttf");
}

@font-face {
  font-family: "cardif-cond";
  src: url("../public/fonts/bnpp-sans-cond.ttf");
}

@font-face {
  font-family: "cardif-light";
  src: url("../public/fonts/open-sans-light.ttf");
}

@font-face {
  font-family: "cardif-regular";
  src: url("../public/fonts/open-sans-regular.ttf");
}

@font-face {
  font-family: "Open Sans";
  src: url("../public/fonts/open-sans-regular.ttf");
}

@layer base {
  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button,
  input[type=number] {
    -webkit-appearance: none;
    margin: 0;
  }

  .scrollbar::-webkit-scrollbar {
    border-radius: 10px;
    height: 5px;
    width: 5px;
    margin: '10px';
  }
  .scrollbar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: lightgray;
  }
  .scrollbar::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: white,
  }
}
