.wrapper {
  display: flex;
  align-items: center;
}

.step-text {
  text-align: center;
  position: absolute;
}

.step-text.top {
  transform: translateY(-50%) translateY(-20px);
}

.step-text.bottom {
  transform: translateY(50%) translateY(20px);
}

.step {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border-width: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.step:after, .step:before {
  width: calc(50% - 9px);
  height: 2px;
  position: absolute;
}

.step:before {
  transform: translatex(-50%) translatex(-10px);
}

.step:after {
  transform: translatex(50%) translatex(10px);
}

.step.active {
  border: 2px solid;
}

.step.selected {
  font-size: 10px;
  color: white;
  font-weight:bold;
}

.step img {
  position: absolute;
  width: 20px;
  height: 20px;
  opacity: 0%;
  z-index: 1;
}

.step.selected>.content {
  width: 10px;
  height: 10px;
  position: absolute;
  border-radius: 50%;
  border: 2px solid white;
}

.checkmark {
  display: inline-block;
  position: relative;
  height: 10px;
  width: 5px;
  bottom: 2px;
  transform: rotate(45deg);
  border-bottom: 2px solid;
  border-right: 2px solid;
  border-color: white;
}
